import React from 'react';
import './Footer.css'
function Footer() {
  return (
    <footer>
  <div class="footer">
    <div class="row">
      <ul>
        <li><a href="#">Contact us</a></li>
        <li><a href="#">Our Services</a></li>
        <li><a href="#">Privacy Policy</a></li>
        <li><a href="#">Terms & Conditions</a></li>
        <li><a href="#">Career</a></li>
      </ul>
    </div>
    
    <div class="row">
      Inferno &copy; 2021 Inferno - All rights reserved || Designed By: Mahesh
    </div>
  </div>
</footer>
  );
}

export default Footer;
